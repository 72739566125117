<template>
  <div class="d-flex justify-content-center mt-10 h-100">
    <div class="d-flex align-items-center align-self-center card p-3 text-center cookies">
      <img src="../../assets/DocQ_Logo_Circle.png" width="80" />
      <h3 class="mt-4"><strong>Welcome Back</strong></h3>
      <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Login',
    computed: {
      isAuthenticated() {
        return this.$auth.isAuthenticated.value
      },
      redirectPath() {
        const redirect = this.$route.query.redirect
        return redirect ? redirect : '/'
      },
    },
    mounted() {
      if (this.isAuthenticated) {
        this.$router.push(this.redirectPath)
      }
    },
  }
</script>

<style scoped>
  .card {
    width: 350px;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #d2d2dc;
    border-radius: 6px;
    -webkit-box-shadow: 0px 0px 5px 0px rgb(249, 249, 250);
    -moz-box-shadow: 0px 0px 5px 0px rgba(212, 182, 212, 1);
    box-shadow: 0px 0px 5px 0px rgb(161, 163, 164);
  }
</style>
